import cn from 'classnames';
import { useCallback } from 'react';

import { AccountRoutes as RoutesAccount } from '@webapp/account/src/resources/routes';
import { useAuthInfoConnect } from '@webapp/common/hooks/use-auth-info-connect';
import { useClickOutside } from '@webapp/common/hooks/use-click-outside';
import { useToggle } from '@webapp/common/hooks/use-toggle';
import { ColorScheme, stopEvent } from '@webapp/common/lib/ui';
import { Burger, LogoDark, LogoLight, ProfileBtn } from '@webapp/ui/lib/icons';
import { Nav } from '@webapp/ui/lib/nav';
import { CssUiComponent } from '@webapp/ui/lib/survey-custom';

import css from './header.css';

const AuthMenu: FC<{
    onLogout: (e: ReactMouseEvent) => void;
    isLoggedIn: boolean;
}> = ({ onLogout, isLoggedIn }) => (
    <div className={css.auth}>
        <a className={cn(css.link, css.account)} href={RoutesAccount.account.link}>
            <ProfileBtn className={css.icon} />
            <div>Личный кабинет</div>
        </a>
        {!isLoggedIn && (
            <a className={css.link} href={RoutesAccount.authLogout.link} onClick={onLogout}>
                Выход
            </a>
        )}
    </div>
);

const NonAuthMenu: FC = () => (
    <div className={css.nonAuth}>
        <a className={cn(css.link, css.login)} href={RoutesAccount.authLogin.link}>
            Вход
        </a>
        <div className={css.span} />
        <a className={cn(css.link, css.register)} href={RoutesAccount.authRegister.link}>
            Регистрация
        </a>
    </div>
);

export const Header: FC<{
    className?: string;
    mode: ColorScheme;
    logoLink: string;
    logout?: () => void;
    hideNoAuth?: boolean;
    isLanding?: boolean;
    page?: 'main';
}> = ({ className, hideNoAuth, logoLink, logout, mode, isLanding, page }) => {
    const { enabled: navActive, off: hideNav, toggle: toggleNav } = useToggle(false);
    const { isLoggedIn, onLogout } = useAuthInfoConnect(logout);
    const Logo = page === 'main' ? LogoLight : LogoDark;

    const handleClickLogout = useCallback(
        (e: ReactMouseEvent): void => {
            stopEvent(e);
            onLogout();
        },
        [onLogout]
    );

    const handleClickBurger = useCallback(
        (e: ReactMouseEvent): void => {
            stopEvent(e);
            toggleNav();
        },
        [toggleNav]
    );

    const outsideRef = useClickOutside(hideNav);

    const elAccount = () => {
        return (
            <div className={cn(css.item, css.mobileShow)}>
                {isLoggedIn ? (
                    <AuthMenu onLogout={handleClickLogout} isLoggedIn={isLoggedIn} />
                ) : (
                    !hideNoAuth && <NonAuthMenu />
                )}
            </div>
        );
    };

    return (
        <div className={CssUiComponent.NO_PRINT} ref={outsideRef}>
            <Nav active={navActive} onClose={handleClickBurger} />
            <header
                id='header'
                className={cn(
                    css.header,
                    { [css.dark]: mode === ColorScheme.DARK, [css.light]: mode === ColorScheme.LIGHT },
                    className
                )}
            >
                <div className={css.container}>
                    <div className={css.logo}>
                        <a href={logoLink} title='testograf'>
                            <Logo />
                        </a>
                    </div>
                    {isLanding && (
                        <div className={cn(css.menu, css.menuLanding)}>
                            <div className={css.item}>
                                <a className={css.link} href='/ru/capabilities-hr'>
                                    Возможности
                                </a>
                            </div>
                            <div className={css.item}>
                                <a className={css.link} href='/ru/pricing-plans'>
                                    Тарифы
                                </a>
                            </div>
                            {elAccount()}
                        </div>
                    )}
                    <div className={css.menu}>
                        {!isLanding && elAccount()}
                        <div className={css.burger}>
                            <Burger className={css.btn} onClick={handleClickBurger} />
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
};
